<template>
  <AppPage
    :show-header="false"
    :show-menu="false"
    class="centered"
    :class="sectionToShow"
    :show-footer="false"
  >
    <div class="split-view">
      <aside class="features-container">
        <div class="features">
          <router-link :to="{name:'Home'}">
            <TheStoryFolderLogo />
          </router-link>
          <h2>Your ultimate YouTube Companion</h2>
          <div class="checklist">
            <div>
              <IconSvg name="check" /> Chat with YouTube Videos
            </div>
            <div>
              <IconSvg name="check" /> Highlight key points
            </div>
            <div>
              <IconSvg name="check" /> Translate languages
            </div>
            <div>
              <IconSvg name="check" /> Dive deeper into topics
            </div>
            <div>
              <IconSvg name="check" /> Reformat for your needs
            </div>
            <div>
              <IconSvg name="check" /> Free, no credit card required
            </div>
          </div>
          <Testimonial
            v-for="(testimonial, index) in testimonials"
            :key="index"
            :headline="testimonial.headline"
            :name="testimonial.name"
            :inline="true"
          />
        </div>
      </aside>
      <aside>
        <div v-if="sectionToShow === 'signup'">
          <form class="section">
            <h2>Create your Free Account</h2>
            <Button
              class="white google"
              size="small"
              @click="withGoogle"
            >
              <IconSvg name="google" />
              Sign up with Google
            </Button>
            <p v-if="false">
              <small>Or use your email</small>
            </p>
            <input
              ref="inputEmail"
              v-model="email"
              type="email"
              placeholder="Email"
              :required="true"
            >
            <input
              ref="inputKey"
              v-model="password"
              type="password"
              placeholder="Choose a password"
              :required="true"
            >
            <p
              v-if="error"
              class="error"
            >
              {{ error }}
            </p>
            <Button
              class="special uppercase"
              size="small"
              label="Start Chatting"
              :has-chevron="true"
              :disabled="!email || !password"
              @click="signup"
            />
            <p class="size-xxs grayMedium">
              By signing up you agree to our Terms of Service
            </p>
          </form>
          <p>
            <a @click="sectionToShow='signin'">Already have an account? Sign in here.</a>
          </p>
        </div>





        <div v-if="sectionToShow === 'signin'">
          <p>
            <TheStoryFolderLogo />
          </p>
          <form class="section">
            <h2>Sign In</h2>
            <Button
              class="white google"
              size="small"
              @click="withGoogle"
            >
              <IconSvg name="google" />
              Sign in with Google
            </Button>
            <p v-if="false">
              <small>Or use your email</small>
            </p>
            <input
              ref="inputEmail"
              v-model="email"
              type="email"
              placeholder="Email"
              :required="true"
            >
            <input
              ref="inputKey"
              v-model="password"
              type="password"
              placeholder="Password"
              :required="true"
            >
            <p
              v-if="error"
              class="error"
            >
              {{ error }}
            </p>
            <Button
              class="special uppercase"
              label="Sign in"
              size="small"
              :disabled="!email || !password"
              @click="signin"
            />
          </form>
          <p><a @click="requestOneTimeSignInLink">Email me a sign in link</a></p>
          <p><a @click="sectionToShow='signup'">Create a Free Account</a></p>
        </div>
        <div
          v-if="sectionToShow === 'link-sent'"
          class="section"
        >
          <h2>Link emailed</h2>
          <p>
            If your email is in our system you will receive an email shortly.
          </p>
        </div>
        <div
          v-if="sectionToShow === 'totp-signin'"
          class="section"
        >
          <h2>Signing in...</h2>
          <p v-if="!error">
            Wait one moment...
          </p>
          <p
            v-else
            class="error"
          >
            {{ error }}<br><br>
            <a @click="sectionToShow='signin'; error=null">Request a new link</a>
          </p>
        </div>
      </aside>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import Button from "@/components/atoms/Button.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin";
import Testimonial from "@/components/molecules/Testimonial.vue";
import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";

export default Vue.extend({
  components: {
    TheStoryFolderLogo,
    IconSvg,
    AppPage,
    Testimonial,
    Button
  },
  mixins: [pageHeadMixin],
  data(){
    return {
      email: '',
      password: '',
      key: '',
      error: '',
      success: '',
      sectionToShow: 'signup'
    }
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters['user/isAuthenticated'];
    },
    testimonials(){
      return this.$store.getters['testimonials/random'](3);
    },
    head(){
      // if(this.sectionToShow == 'signup'){
      //   return {
      //     title: 'Sign Up for StoryFolder | Convert Videos to Storyboards',
      //     description: 'Sign up for StoryFolder to start converting videos into detailed storyboards, slides, and production notes. Start for free today!',
      //     robots: 'noindex,follow',
      //   }
      // }else if(this.sectionToShow == 'signin') {
      //   return {
      //     title: 'Sign In to StoryFolder | Convert Videos to Storyboards',
      //     description: 'Sign in to StoryFolder to start converting videos into detailed storyboards, slides, and production notes.',
      //     robots: 'noindex,follow',
      //   }
      // }
      return {};
    }
  },
  watch: {
    isAuthenticated: {
      handler: async function(isAuthenticated){
        if(isAuthenticated){
          console.log('subscriptions loading');
          await this.$store.dispatch('subscriptions/load');
          console.log('subscriptions loaded');
          if(this.$route.query.redirect){
            this.$router.push(this.$route.query.redirect);
          }else{
            this.$router.push({name: 'onboard'});
          }
        }
      },
      immediate: true
    },
    sectionToShow(){
      // Reset the error if they switch between screens
      this.error = null;
    }
  },
  mounted(){
    // This is for one-time login links
    // Automatically log in using the token in the URL
    if(this.$route.name === 'totp-signin'){
      this.sectionToShow = 'totp-signin';
      this.signInWithTemporaryOneTimePassword();
    }
    if(this.$route.name === 'signin'){
      this.sectionToShow = 'signin';
    }
  },
  methods: {
    signup(){
      this.$store.dispatch('user/signup', {
        email: this.email,
        password: this.password
      }).catch(e => {
        this.error = e.message
      });
    },
    signin(){
      this.$store.dispatch('user/signin', {
        email: this.email,
        password: this.password
      }).catch(e => {
        this.error = e.message
      });
    },
    requestKey(){
      this.$store
          .dispatch('user/requestKey', {
            email: this.email
          })
          .then(e => {
            this.success = e;
            this.email = '';
          })
          .catch(e => {
            this.error = e.message;
          });
    },
    withGoogle(){
      let url = `/api/users/auth/google`;
      if(this.$route.query.redirect){
        const redirect = this.$route.query.redirect;
        const urlEncodedRedirect = encodeURIComponent(redirect);
        url += `?redirect=${urlEncodedRedirect}`;
      }
      window.location.href = url;
    },
    requestOneTimeSignInLink(){
      if(!this.email){
        this.error = 'Please enter your email address';
        return;
      }
      this.$store.dispatch('user/requestSignInLink', this.email)
          .then(() => {
            this.sectionToShow = 'link-sent';
          })
          .catch(e => {
            this.error = e.message
          });
    },
    signInWithTemporaryOneTimePassword(){
      this.$store
          .dispatch('user/signInWithTOTP', this.$route.params.token)
          .then(data => {
            console.log({data});
          })
          .catch(e => {
            console.log(e);
            this.error = e;
          });
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';

.signin {
  .split-view {
    grid-template-columns: 1fr;
    .features-container {
      display: none !important;
    }
  }
}

.split-view{
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  align-items: stretch;
  justify-content: stretch;
  aside{
    display: flex;
    align-items: center;
    justify-content: center;
    &.features-container{
      background: white;
    }
  }
  .features-container {
    display: none;
  }
}
.google{
  display: none;
}
.centered{
  text-align: center;
  font-size: 110%;
}
.section {
  background: @white;
  padding: 2em 2em;
  border-radius: 1em;
  width: 400px;
  max-width: 90vw !important;
  box-sizing: border-box;
  .shadow();
}
/deep/ .page-content{
  margin: 0 auto;
}
h2{
  margin-top: 0.5em;
  font-size: 1.5rem;
  font-weight: 600;
}

p{
  margin: 0 0 1em 0;
  a{
    font-size: 80%;
    color: @purpleLight;
    &:hover{
      color: @black;
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
form{
  margin:0 auto 1em auto;
  input, button{
    padding: 0.9rem 1.5rem;
    font: inherit;
    box-sizing: border-box;
    clear: both;
    width: 100%;
    border-radius: 6px;
    border: none;
    text-align: center;
    outline: none;
  }
  input{
    margin-bottom: 1em;
    background: @grayLightMedium;
    &:focus{
      background: @grayLight;
    }
  }

  button{
    font-weight: 600;
  }
}

p.error {
  color: #fc7a7a;
  font-size: 90%;
}
p.green {
  color: #b3fc96;
}


.features{
  text-align: left;
  max-width: 400px;
  margin:0 auto;
  background: white;
  .logo{
    font-size: 2em;
  }
  .check{
    color: red;
  }
  .checklist{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 1.5em;
  }
}

.testimonial {
  font-size: 70%;
  background: transparent;
  padding: 0;
  margin-top: 1em;
}

@media(min-width: @secondbreakpoint) {
  .split-view {
    grid-template-columns: 1fr 1fr;

    .features-container {
      display: flex;
    }
  }
}
</style>
