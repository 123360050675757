<template>
  <div
    class="testimonial"
    :class="{inline}"
  >
    <div class="rating">
      <IconSvg
        v-for="i in 5"
        :key="i"
        name="star"
      />
    </div>
    <div
      v-if="headline"
      class="summary"
    >
      &ldquo;{{ headline }}&rdquo;
    </div>
    <div
      v-if="review"
      class="review"
    >
      {{ review }}
    </div>
    <div
      v-if="name"
      class="name"
    >
      - {{ name }}
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import IconSvg from "@/components/atoms/IconSvg.vue";

export default Vue.extend({
  name: 'PageLogin',
  components: {
    IconSvg
  },
  props: {
    headline: {
      type: String,
      default: null
    },
    review: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
})
</script>
<style lang="less" scoped>

@import '../../less/variables';
.testimonial {
  flex: 0 0 300px;
  will-change: transform;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  padding: 1.75em 1.75em 1.5em 1.75em;
  background: #fff;
  border-radius: 1.5em;
  &.inline{
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
  }
}
.rating{
  display: flex;
  gap: 3px;
  color: @red;
}
.summary{
  font-size: 1.2em;
  font-weight: 600;
  color: @black;
}
.review{

}
.name{
  color: @gray2;
  font-size: 0.9em;
}
</style>